import Store from "../../store";

const routes = [{
    path: "/",
    name: "home",
    alias: "/home",
    // component: HomePage,
    component: () =>
      import(/* webpackChunkName: "HomePage" */ "@/pages/HomePage.vue"),
    beforeEnter: (to, from, next) => {
      // console.log(to, from);
      if (Store.getters.isAuthenticated) {
        next("/shop");
      } else next();
    },
  },
];

export default routes;