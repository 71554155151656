// import { FIREBASE_APIKEY } from "@/config/firebase";
// import Axios from "axios";
import { createStore } from "vuex";
import authModule from "./modules/auth";
import shopModule from "./modules/shop";
import cartModule from "./modules/cart";

export default createStore({
  modules: {
    authModule,
    shopModule,
    cartModule
  }
});
