const shopRoutes = [
  {
    path: "/shop",
    name: "shop page",
    // component: ShopPage,
    // beforeEnter: (to, from, next)=>{
    //   console.log(to, from);
    //   if(!Store.getters.isAuthenticated) {
    //     next("/");
    //   }
    //   else next();
    // },
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "ShopCode" */ "@/pages/ShopPage.vue"),
  },
  {
    path: "/v2/shop",
    name: "shop page 2",
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TheShopLayout2" */ "@/layouts/v2/TheShopLayout.vue"),
      children: [
        {
          path: "",
          component: () =>
          import(/* webpackChunkName: "ShopPage2" */ "@/pages/v2/ShopPage.vue"),
        },
        // {
        //   path: "create/product",
        //   component: () =>
        //   import(/* webpackChunkName: "ShopCode" */ "@/pages/v2/CreateProductPage.vue"),
        // }
      ],
  },
  {
    path: "/shop/create/product",
    // component: CreateProductPage,
    component: () =>
      import(
        /* webpackChunkName: "ShopCode" */ "@/pages/CreateProductPage.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/shop/read/product/:id",
    name: "ReadProduct",
    // component: ReadProductPage,
    component: () =>
      import(
        /* webpackChunkName: "ReadProductPage" */ "@/pages/ReadProductPage.vue"
      ),
    // props mapt alle url params zu vue props - daher keine data (id) oder lifecycle hooks in der component mehr benötigt
    props: true,
    meta: {
      requiresAuth: true,
      enterTransition: "rubberBand",
    },
  },
];

export default shopRoutes;
