<template>
  <!-- Hook für den vue router -->
  <router-view></router-view>
  <!-- <router-view v-slot="{ Component }">
    <transition
      :enter-active-class="enterActiveClasses"
      leave-active-class="animate__animated animate_fadeOut"
      mode="out-in"
    >
      <component :is="Component" :key="$route.path"></component>
    </transition>
  </router-view> -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
    }),
    token() {
      return this.$store.getters.token;
    },
    // enterActiveClasses(){
    //   if(this.$route.meta.enterTransition){
    //     return `animate__animated animate__${this.$route.meta.enterTransition}`;
    //   }
    //   return 'animate__animated animate__fadeIn';
    // }
  },
  watch: {
    isAuthenticated(newValue, oldValue) {
      console.log("isAuthenticated watcher: ", oldValue, newValue);
      if (!newValue) {
        this.$router.push("/");
      }
    },
    token: {
      handler() {
        console.log("token watcher: ");
        if (this.token) {
          this.$store.dispatch("fetchProducts");
        }
      },
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch("autoSignin");
  },
};
</script>

<style>
@import "/~bootstrap/dist/css/bootstrap.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~animate.css/animate.min.css";

.bg-vue {
  background-color: rgb(52, 73, 94);
  color: white;
}
.bg-vue2 {
  background-color: rgb(65, 184, 131);
  color: white;
}
.text-vue {
  color: rgb(52, 73, 94);
}
.text-vue2 {
  color: rgb(65, 184, 131);
}


</style>
