// import { FIREBASE_APIKEY } from "@/config/firebase";
import Axios from "axios";

const state = {
  products: [],
};

const getters = {
  products: (state) => state.products,
  product: (state) => (id) =>
    state.products.find((product) => product.id === id),
};
const mutations = {
  setProducts(state, payload) {
    state.products = payload;
  },
  addProduct(state, payload) {
    state.products.push(payload);
  },
};
const actions = {
  fetchProducts(context) {
    console.log("fetchProducts called");

    const token = context.rootState.authModule.token;

    Axios.get(
      `https://vue-3-shop-backend-58099-default-rtdb.europe-west1.firebasedatabase.app/products.json?auth=${token}`
    )
      .then((response) => {
        console.log("get response = ", response);

        const productsVO = [];
        for (const id in response.data) {
          productsVO.push({
            id: id,
            ...response.data[id],
          });
        }

        context.commit("setProducts", productsVO);
      })
      .catch((error) => {
        console.log("get error = ", error);
      });
  },
  storeProduct(context, payload) {
    console.log(context);

    const productItemVO = {
      title: payload.title,
      description: payload.description,
      price: payload.price,
    };

    const token = context.rootState.authModule.token;

    Axios.post(
      `https://vue-3-shop-backend-58099-default-rtdb.europe-west1.firebasedatabase.app/products.json?auth=${token}`,
      productItemVO
    )
      .then((response) => {
        console.log("response = ", response);
      })
      .catch((error) => {
        console.log("error = ", error);

        throw new Error(error);
      });
  },
};

const shopModule = {
  state,
  getters,
  mutations,
  actions,
};

export default shopModule;
