// import HomePage from "@/pages/HomePage.vue";
// import ShopPage from '@/pages/ShopPage.vue'
// import CreateProductPage from "@/pages/CreateProductPage.vue";
import { createRouter, createWebHistory } from "vue-router";
import Store from "../store";
import routes from "../router/routes";
// import ReadProductPage from "@/pages/ReadProductPage.vue";
// import NotFoundPage from "@/pages/NotFoundPage.vue";


const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...routes,
    {
      path: "/:pathMatch(.*)*",
      // component: NotFoundPage,
      component: () =>
        import(/* webpackChunkName: "NotFoundPage" */ "@/pages/NotFoundPage.vue"),
      // redirect: "/",
    },
  ],
});

router.beforeEach((to, from, next) => {
  // console.log('beforeEach : ', to, Store.getters.isAuthenticated);

  if (to.meta.requiresAuth && !Store.getters.isAuthenticated) {
    next("/");
  } else next();
});

export default router;
