import { FIREBASE_APIKEY } from "@/config/firebase";
import Axios from "axios";

let timer;

const state = {
  userId: null,
  token: null,
};

const getters = {
    isAuthenticated: (state) => !!state.token,
    token: (state) => state.token,
};
const mutations = {
  setUser(state, payload) {
    state.userId = payload.userId;
    state.token = payload.token;
  },
};
const actions = {
  auth(context, payload) {
    let url = "";
    if (payload.mode === "signin") {
      url =
        "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=" +
        FIREBASE_APIKEY;
    } else if (payload.mode === "signup") {
      url =
        "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=" +
        FIREBASE_APIKEY;
    } else return "";

    const authVO = {
      email: payload.email,
      password: payload.password,
      returnSecureToken: true,
    };

    return Axios.post(url, authVO)
      .then((response) => {
        // console.log('firebase response: ', response);

        const expiresIn = response.data.expiresIn * 1000;
        const expDate = new Date().getTime() + expiresIn;

        // console.log('setToken to LC - ', response.data.idToken);

        // Daten im localstorage speichern
        localStorage.setItem("token", response.data.idToken);
        localStorage.setItem("userId", response.data.localId);
        localStorage.setItem("expiresIn", expDate);

        // console.log('setTimeout to ', expiresIn);

        timer = setTimeout(() => {
          context.dispatch("autoSignout");
        }, expiresIn);

        context.commit("setUser", {
          userId: response.data.localId,
          token: response.data.idToken,
        });
      })
      .catch((error) => {
        console.log("error: ", { error });
        const errorMessage = new Error(
          error.response.data.error.message || "UNKNOWN ERROR"
        );
        throw errorMessage;
      });
  },
  signUp(context, payload) {
    // console.log("signup: this = ", this);
    const signupVO = {
      ...payload,
      mode: "signup",
    };
    return context.dispatch("auth", signupVO);
  },
  signIn(context, payload) {
    // console.log("signin: this = ", this);
    const signinVO = {
      ...payload,
      mode: "signin",
    };
    return context.dispatch("auth", signinVO);
  },
  autoSignin(context) {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const expiresIn = localStorage.getItem("expiresIn");

    const timeLeft = Number(expiresIn) - new Date().getTime();

    if (timeLeft < 0) {
      return;
    }
    timer = setTimeout(() => {
      context.dispatch("autoSignout");
    }, expiresIn);

    // console.log('setTimeout to ', expiresIn);

    if(token && userId) {
        context.commit("setUser", {
            token, 
            userId
        })
    }
  },
  signOut(context) {
    // console.log("signOut: this = ", this);
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("expiresIn");

    clearTimeout(timer);

    context.commit("setUser", {
      token: null,
      userId: null,
    });
  },
  autoSignout(context) {
    // console.log("autoSignout: this = ", this);

    context.dispatch("signOut");
  },
};

const authModule = {
  state,
  getters,
  mutations,
  actions,
};

export default authModule;
